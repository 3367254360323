import * as React from "react"
import { SEO } from "../components/seo"
import Layout from '../components/layout'

const AboutPage = () => {
  return (
	<Layout>
		<main>
			<section id="hero">
				<h1>About Page</h1>
			</section>
			<section id="intro">
				<p>This is an about page.</p>
			</section>
		</main>
	</Layout>
  )
}
export default AboutPage

export const Head = () => (
	<SEO
        title="About Page"
        description="This is the about page of the sitekeeper template."
    />
)
